import { Controller } from 'stimulus';
import { removeClass, addClass } from '~/lib/utils/class_fns';

export default class extends Controller {
  static targets = ['selectOption', 'fromHour', 'toHour', 'option', 'modalFromHour', 'modalToHour', 'modalSubmit', 'customHours']
  static values = {
    precheck: Number,
    currentData: Object,
  }

  connect() {
    this.customHoursLabel = this.customHoursTarget.textContent;
    this.maybePrecheckTarget();
    this.maybeWithCurrentDataValues();
  }

  maybeWithCurrentDataValues() {
    if (!this.hasCurrentDataValue) {
      return ;
    }
    console.log(this.currentDataValue);
    console.log(this.optionTargets);
    const target = this.optionTargets.find(option => option.dataset.optionName == this.currentDataValue.select_option);
    console.log(target);
    if (target) {
      this.selection({target: target});
    }

    if (this.currentDataValue.select_option == "custom_hours") {
      this.modalLoadPickValues(this.currentDataValue.from_hour, this.currentDataValue.to_hour);
    }
  }

  maybePrecheckTarget() {
    if (this.precheckValue == 0) {
      return ;
    }

    const checkTarget = this.optionTargets[this.precheckValue - 1];
    if (checkTarget) {
      this.selection({ target: checkTarget });
    }
  }

  hourTolabe(hour) {
    const h = parseInt(hour);
    if (h >= 12) {
      return `${h != 12 ? h - 12 : h}:00PM`;
    } else {
      return `${h}:00AM`;
    }
  }

  modalLoadPickValues(from, to) {
    const label = `${this.hourTolabe(from)} - ${this.hourTolabe(to)}`
    this.setInputs({
      fromHour: from,
      toHour: to,
      optionName: 'custom_hours'
    });
    this.customHoursTarget.textContent = `${this.customHoursLabel} (${label})`;
  }

  modalPick() {
    const from = this.modalFromHourTarget.value;
    const to = this.modalToHourTarget.value;
    this.modalLoadPickValues(from, to);
  }

  onModalFromHour(event) {
    let options = this.modalToHourTarget.selectize.options;
    const fromHour = parseInt(this.modalFromHourTarget.value) + 2;
    Object.entries(options).forEach(([_key, option]) => {
      const value = parseInt(option.value);
      option.disabled = value < fromHour;
    });
    this.modalToHourTarget.selectize.clear();
    this.modalToHourTarget.selectize.enable();
    this.modalToHourTarget.selectize.renderCache = {};
    this.modalToHourTarget.selectize.refreshOptions();
  }

  onModalToHour() {
    if (this.validateModal()) {
      removeClass(this.modalSubmitTarget, this.disabledClass);
    } else {
      addClass(this.modalSubmitTarget, this.disabledClass);
    }
  }

  reset() {
    this.fromHourTarget.value = '';
    this.toHourTarget.value = '';
    this.selectOptionTarget.value = '';
    this.unTouchAll();
  }

  selection(event) {
    const target = event.target;
    this.unTouchAll();
    addClass(target, this.activateClass);
    this.setInputs(target.dataset);
  }

  setInputs(dataset) {
    this.fromHourTarget.value = dataset.fromHour || '';
    this.toHourTarget.value = dataset.toHour || '';
    this.selectOptionTarget.value = dataset.optionName;
  }

  unTouchAll() {
    removeClass(this.optionTargets, this.activateClass);
  }

  validateModal() {
    return this.modalFromHourTarget.value != '' && this.modalToHourTarget.value != '';
  }

  get activateClass() {
    return 'Polaris-Button--primary';
  }

  get disabledClass() {
    return 'Polaris-Button--disabled';
  }
}
